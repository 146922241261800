<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>线索有效转化</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>线索录入时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-popover placement="bottom" trigger="click" v-model="visible">
                        <template slot="content">
                            <div class="more-search">
                            <a-form layout='inline'>
                                <a-form-item class="block-line" label="来源渠道">
                                    <a-tree-select
                                      v-model="paramsData.channel_id"
                                      :tree-data="parentChannels"
                                      tree-checkable
                                      allowClear
                                      treeNodeFilterProp="title"
                                      :maxTagCount='1'
                                      :maxTagTextLength='5'
                                      :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                      :show-checked-strategy="SHOW_ALL"
                                      placeholder="请选择来源渠道"
                                  />
                                </a-form-item>
                                <a-form-item class="block-line" label="所属校区">
                                  <a-select v-model="paramsData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                                      <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                  </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="添加人">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.created_by' allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                            <div class="more-search__buttons">
                                <a-button type="dashed" @click="closeSearch">取消</a-button>
                                <a-button @click="reset">重置</a-button>
                                <a-button type="primary" @click="toScreen">搜索</a-button>
                            </div>
                            </div>
                        </template>
                        <a-button>更多<a-icon type="down" /></a-button>
                        </a-popover>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <goal :loading='loading'  :item ='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loading' :item ='channelDataL' title="渠道大类客户转化"  :start_date='paramsData.start_date' :end_date='paramsData.end_date' subtext='总量'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loading' :item ='channelDataR' title="渠道细分客户转化"  :start_date='paramsData.start_date' :end_date='paramsData.end_date' subtext='总量'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loading' :item ='channelCountData' title="渠道签约数"  :start_date='paramsData.start_date' :end_date='paramsData.end_date' subtext='总量'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loading' :item ='channelSalesData' title="渠道签约金额"  :start_date='paramsData.start_date' :end_date='paramsData.end_date' subtext='金额'/>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import goal from './goal'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'change',
  components:{
    goal,
    statistics
  },
  data() {
    return {
      loading:false,
      visible:false,
      leads_data:{},
      channelCountData:{},
      channelSalesData:{},
      channelDataL:{},
      channelDataR:{},
      studios:[],
      counts:[],
      channelsId:[],
      parentChannels: [],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:undefined,
        filter_type:'month'
      },
      staffList:[]
    }
  },
  mixins: [ranges],
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
    this.getStaff()
    this.getSourceChannel()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    getList(obj){
      this.getLeads(obj)
    },
    async getLeads(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisCrmLeadsTransferAction', obj)
      this.leads_data = res.data.leads_transfer_customer
      this.channelDataL = res.data.channel
      this.channelDataR = res.data.sub_channel
      this.channelCountData = res.data.channel_count
      this.channelSalesData = res.data.channel_sales
      this.loading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCrmChannelCountAction', obj)
      this.counts = res.data
    },
    async getSourceChannel() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getStaff(val){
        let obj = {q:val}
        let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
        this.staffList = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen()
    },
    
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    },
    closeSearch() {
      this.visible = false
    },
    reset(){
      this.paramsData.channel_id = undefined
      this.channelsId = []
      this.paramsData.studio_id = undefined
      this.paramsData.created_by = undefined
    },
    handleSearch(val){
        this.getStaff(val)
    },
  }
}
</script>